import React from 'react';
import SEO from "../components/seo"
import Layout from "../components/layout"
import Grid from '@material-ui/core/Grid';
import Mission from '../components/Mission'
import SliderBootstrapStatic from '../components/SliderBootstrapStatic';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    '& li': {
      listStyleType: 'disc',
    },
    '& p': {
      textAlign: 'justify',
    }
  },
});
export default function AboutUs() {
  const classes = useStyles();
  return (
    <Layout>
      <SEO
        title="About Us"
        description="Savante consulting Limited is a company registered in Nigeria to carry out business as a consultancy to provide information to businesses and individuals that will promote efficiency in business administration and management. Savante Consulting will partner clients from conception to run-in of any investment and project decision. "
      />
      <br /><br />

      <SliderBootstrapStatic title="About Us" />
      <div className="container">
        <Grid container spacing={10}>
          <Grid item xs={12} md={8} lg={8}>
            <div className={classes.root}>
              <p>
                Savante consulting Limited is a company registered in Nigeria to carry out business as a consultancy to provide information to businesses and individuals that will promote efficiency in business administration and management. Savante Consulting will partner clients from conception to run-in of any investment and project decision.
              </p>
              <p>
                Savante consulting Ltd services will include Market study, product registration and regulatory approvals, Strategy formulation and business plan, turn-key construction of manufacturing plants, trade mark and brand protection services, research, training and conferences.
              </p>
              <p>
                We service clients in the life sciences including manufacturers and marketers of Pharmaceuticals, personal care product, food and beverages, medical devices and raw materials that support these industries. We also help service providers in the life science like health maintenance organizations.
              </p>
              <p>
                Referenced as a reliable source of well researched industry information and disciplined collaborator in project execution.
              </p>
              <p>
                Savante consulting Limited will strive to reduce and manage business risks by providing our clients and their financiers with business services that will enable intelligent decisions and timely executions in the Food and Drugs industry.
              </p>
              <ul className="pl-20 listDisk">
                <li>Knowledge: Knowledge is our raison d’etre. We shall invest in it, through training and research, and manage it for the benefit of our staff and clients.</li>
                <li>People: Our people constitute the real asset of the company. We will carefully select them for their intelligence and integrity, carefully supervise them to cultivate attention to detail, right attitude and skills.</li>
                <li>Profit: Profit is essential for business continuity, and it is also a proof of our customers’ confidence in us. We will generate profit as proof that we are deserving of our customers’ trust and to reward all our stakeholders.</li>
              </ul>
              <p>

              </p>


              <h4>What we do</h4>
              <ul className="pl-20 listDisk">
                <li>Trade mark Registration with The Ministry of Commerce </li>
                <li>Compiling information to support registration of products with NAFDAC, and regulatory agencies in foreign countries.</li>
                <li>Validation of premises and manufacturing plants and their registration with NAFDAC, Pharmacists council of Nigeria and Quality certification by WHO and ISO. This will entail writing of SOPs, work instructions and other documentations to support the application.</li>
                <li>Clinical Trial application for prescription medicines and medical devices.</li>
                <li>Market Study to aid investment decision and strategy formulation.</li>
                <li>Business plan</li>
                <li>Turnkey construction projects in pharmaceuticals, personal care, food and beverages industry.</li>
                <li>Investigation of Franchise and trade mark violations by fakes, parallel imports and pass-offs.</li>
                <li>Trade mark and brand protection services, including SMS based authentication of products by end users.</li>
                <li>CC conducts research and analyzes industry trends to aid business decision and strategy formulation.</li>
                <li>Market entry of healthcare brands into foreign markets.</li>
                <li>Valuation of, and Mediating sale and purchase of healthcare/pharmaceutical brands.</li>
                <li>Organize training programs to improve knowledge base of industry practitioners on topical issues.</li>
                <li>Consulting services for Nigeria and Foreign Government agencies and NGOs. </li>
              </ul>

            </div>

          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <Mission />
          </Grid>
        </Grid>
        <br /><br />
      </div>
    </Layout>
  );
}